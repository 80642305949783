import * as React from "react";

import "./FeatureBanner.scss";

export interface IBanner {
    icon: {
        asset: {
            url: string,
        },
    };
    subtitle: string;
    title: string;
}

export interface Props {
    page: string;
    about: string;

    banner: IBanner[];
}

export const FeatureBanner: React.FunctionComponent<Props> = (props) => {
    const { banner, about } = props;
    return (
        <div className={`subscribe__banner-container ${props.page}-background`} id={props.page}>
            <h2 style={props.page === "trainer" ? {} : { display: "none" }}>{about}</h2>
            <div className={`subscribe__banner ${props.page}-background`}>
                {banner.map((item) => (
                    <div className="subscribe__banner-text" key={`${item.title}-${item.subtitle}`}>
                        <img src={item.icon.asset.url} alt={item.title} />
                        <p className="subscribe__banner-title">{item.title}</p>
                        <p className="subscribe__banner-description">{item.subtitle}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};
