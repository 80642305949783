import * as React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { FeatureBanner } from "@fitplan/lib/components/FeatureBanner";
import { ChevronBanner } from "@fitplan/lib/components/ChevronBanner";

import AboutTrainer from "../components/trainers/AboutTrainer";
import localize from "../hoc/Localize";
import PageWrapper from "../components/pageWrapper/PageWrapper";
import PlanGrid from "../components/planGrid";
import {Subscribe} from "../components/subscribe";
import { getAthleteName } from "../utils/helpers";
import { ITypes, IAllFitplanPlans, ISanityTrainer } from "../types/sanity";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";

import "../components/trainers/trainer.scss";

const createMetaData = (athlete: FitplanAthlete) => {
    const { athleteDescription } = athlete;
    const name = getAthleteName(athlete);
    return {
        title: `${name} Workout Plans | Fitplan`,
        description: athleteDescription,
        keywords: [name],
    };
};

export interface Props {
    data: AthleteData;
}

const AthletesPage: React.SFC<Props> = props => {
    const { sanityTrainer, allFitplanPlans, sanityPlanDetail, planTypes } = props.data;
    const athlete = props.data.fitplanAthletes;
    const metaData = createMetaData(athlete);
    const name = getAthleteName(athlete);
    const planTitle =
        process.env.GATSBY_L18N_LOCALE === "en"
            ? `${getAthleteName(athlete, true)} ${sanityTrainer.plansTitle}`
            : `${sanityTrainer.plansTitle} de ${name}`;
    const plans = { edges: (allFitplanPlans || { edges: [] }).edges };

    return (
        <PageWrapper {...props}>
            <Helmet
                title={metaData.title}
                meta={[
                    { name: "description", content: metaData.description },
                    { name: "keywords", content: metaData.keywords.join(", ") },
                    {
                        name: "og:image",
                        content: `./images/og/${athlete.slug}.jpg`,
                    },
                ]}
            />
            <div className="trainer">
                <ChevronBanner
                    src={athlete.athleteImageWide}
                    subtitle={sanityTrainer.header}
                    title={name}
                    button={sanityTrainer.startButton}
                    branchLink={athlete.link}
                />
                <PlanGrid
                    title={planTitle}
                    allFitplanPlans={plans}
                    planTypes={planTypes}
                />
                <AboutTrainer
                    src={athlete.athleteImageWide}
                    title={sanityTrainer.trainerTitle}
                    description={athlete.athleteDescription}
                    component="trainer"
                />
                <FeatureBanner
                    page="trainer"
                    banner={sanityTrainer.banner}
                    about={sanityTrainer.aboutFitplan}
                />
            </div>
            <div style={{background: "#3a3b3e"}}>
                <Polygon polygonColor="#1b1c1c" />
            </div>
            <Subscribe />
            <div style={{background: "#212121"}}>
                <Polygon polygonColor="#111213" borderColor="#00ffb3" />
            </div>
        </PageWrapper>
    );
};

export const query = graphql`
    query fitplanAthleteQuery($slug: String) {
        planTypes {
            num0
            num1
            num2
            num3
            num4
            num5
            num6
            num7
            num8
            num9
            num10
            num11
            num12
            num13
            num14
            num15
            num16
            num17
        }
        sanityPlanDetail {
            aboutPlan {
                about {
                    types {
                        zero {
                            _type
                            en
                            es
                        }
                        one {
                            _type
                            en
                            es
                        }
                        two {
                            _type
                            en
                            es
                        }
                        three {
                            _type
                            en
                            es
                        }
                        four {
                            _type
                            en
                            es
                        }
                        five {
                            _type
                            en
                            es
                        }
                        six {
                            _type
                            en
                            es
                        }
                        seven {
                            _type
                            en
                            es
                        }
                        eight {
                            _type
                            en
                            es
                        }
                        nine {
                            _type
                            en
                            es
                        }
                        ten {
                            _type
                            en
                            es
                        }
                        eleven {
                            _type
                            en
                            es
                        }
                        twelve {
                            _type
                            en
                            es
                        }
                        thirteen {
                            _type
                            en
                            es
                        }
                        fourteen {
                            _type
                            en
                            es
                        }
                        fifteen {
                            _type
                            en
                            es
                        }
                        sixteen {
                            _type
                            en
                            es
                        }
                        seventeen {
                            _type
                            en
                            es
                        }
                    }
                }
            }
        }
        sanityTrainer {
            header {
                _type
                en
                es
            }
            startButton {
                _type
                en
                es
            }
            trialButton {
                _type
                en
                es
            }
            plansTitle {
                _type
                en
                es
            }
            trainerTitle {
                _type
                en
                es
            }
            successTitle {
                _type
                en
                es
            }
            view {
                _type
                en
                es
            }
            banner {
                icon {
                    asset {
                        url
                    }
                }
                subtitle {
                    _type
                    en
                    es
                }
                title {
                    _type
                    en
                    es
                }
            }
            aboutFitplan {
                _type
                en
                es
            }
        }
        allFitplanPlans(filter: { athleteSlug: { eq: $slug } }) {
            edges {
                node {
                    url
                    planName
                    planType
                    planImage
                }
            }
        }
        fitplanAthletes(slug: { eq: $slug }) {
            firstName
            lastName
            sex
            slug
            athleteImageWide
            link
            athleteDescription
            athleteScreenshot
            athleteVideo480
            athleteVideo720
            athleteVideo1080
            athletePlans {
                id
                name
                athleteFirstName
                athleteLastName
                sex
                slug
                goalFlag
                featured
                imageUrl
                daysCount
                femaleBootyGains
                femaleBuildAndBurn
                femaleShredFat
                femaleToneAndTighten
                maleAthleticPerformance
                maleBulkUp
                maleShredFat
                athleteSlug
                url
            }
        }
    }
`;

interface AthleteData {
    sanityPlanDetail: SanityPlanDetail;
    sanityTrainer: ISanityTrainer;
    allFitplanPlans: IAllFitplanPlans;
    fitplanAthletes: FitplanAthlete;
    planTypes: ITypes;
}

interface FitplanAthlete {
    firstName: string;
    lastName: string;
    sex: string;
    slug: string;
    athleteImageWide: string;
    link: string;
    athleteDescription: string;
    athleteScreenshot: string;
    athleteVideo480: string;
    athleteVideo720: string;
    athleteVideo1080: string;
    athletePlans: AthletePlan[];
}

interface AthletePlan {
    id: number;
    name: string;
    athleteFirstName: string;
    athleteLastName: string;
    sex: string;
    slug: string;
    imageUrl: string;
    daysCount: number;
    maleAthleticPerformance: boolean;
    maleBulkUp: boolean;
    maleShredFat: boolean;
    athleteSlug: string;
    url: string;
}

interface SanityPlanDetail {
    aboutPlan: AboutPlan;
}

interface AboutPlan {
    about: About;
}

interface About {
    types: ITypes;
}

export default localize(AthletesPage);
